/* eslint-disable react/prop-types */
import { graphql } from 'gatsby'
import React from 'react'
import { renderRichText } from 'gatsby-source-contentful/rich-text'
import styled from 'styled-components'
import device from '../utils/mq'
import SEO from '../components/Seo'

const Wrapper = styled.div`
  max-width: 1100px;
  margin: 0 auto;
  margin-bottom: 40px;
  h1 {
    font-size: 50px;
    letter-spacing: -3px;
    color: #5a55ab;
    text-align: left;
    font-weight: 700;
    @media ${device.tablet} {
      font-size: 70px;
    }
  }

  p {
    max-width: 1000px;
    margin: 0 0 1.56em;
  }
`

const DataPrivacy = ({ data: { contentfulBasicPage } }) => {
  const pageContent = renderRichText(contentfulBasicPage.mainContent)

  return (
    <Wrapper>
      <SEO
        title="Innential"
        description="Innential helps your teams perform and grow through hyper personalised learning."
      />
      <section>
        <h1>{contentfulBasicPage.headline}</h1>
        <div>{pageContent}</div>
      </section>
    </Wrapper>
  )
}

export default DataPrivacy

export const query = graphql`
  {
    contentfulBasicPage(id: { eq: "88a5dff4-447b-5d1f-bc8a-8e39fc2def72" }) {
      headline
      mainContent {
        raw
      }
    }
  }
`
